﻿/// <reference path="../entries/main">
// ------------- BREAKPOINTS MAP ------------- //

$breakpoints: (
    x-large: $x-large-media-query-size,
    large : $large-media-query-size,
    medium: $medium-media-query-size,
    tablet: $tablet-media-query-size,
    small : $small-media-query-size,
    mobile : $mobile-media-query-size
);

$gutter-map-h: (  // for use between in-component content in columns
                // DON'T REMOVE ANY BREAKPOINTS.
                // Should be smaller than $standard-padding-map at all breakpoints to prevent overflow of flex grid content w/ "pocket" margins
        null        : $max-gutter-width,
        large       : 2rem,
        medium      : 2rem,
        small       : 1.25rem
    );

$gutter-map-small: (  // for use in form items, or any other content that doesn't need a massive horizontal gap
                // DON'T REMOVE ANY BREAKPOINTS.
        null        : 2rem,
        large       : 1.25rem,
        medium      : 1rem,
        small       : 1rem
    );

$calendar-padding-map: $gutter-map-h;

$gutter-map-v: ( // vertical rhythm in in-component layouts
                // DON'T REMOVE ANY BREAKPOINTS.
        null        : $max-gutter-height,
        large       : 2rem,
        medium      : 1.5rem,
        small       : 1.5rem
    );

// Organizing map for component spacing in grid layouts
$grid-spacing-map: (
        null        : 2rem,
        large       : 2rem,
        tablet      : 1.375rem,
        small       : 1.375rem
    );

$standard-padding-map: ( // for global use on page margins. mainly used for content-containers wrapping the inner block Extend %standard-padding-h
        null        : $max-page-margin,
        large       : 4rem,
        medium      : 2.5rem,
        small       : 1rem
    );

$standard-padding-map-v: ( // for global use on page margins. mainly used for content-containers wrapping the inner block Extendor %standard-padding-v
    null : 4rem, 
    large : 4rem, 
    medium : 2rem, 
    small : 2rem );

$mega-nav-padding-map: ( // for global use on page margins. mainly used for content-containers wrapping the inner block Extend %standard-padding-h or %standard-padding-v
        null        : 2.8125rem,
        large       : 2.8125rem,
        medium      : 1.375rem,
        small       : 1.375rem
    );

$small-gutter-map-v : ( // padding block items or similar usage (meganav)
        null     : 1.375rem,
        medium      : .75rem,
        small       : .75rem
    );

$small-gutter-map-h : ( // gap between inline-block items or similar usage (utility nav items)
        null     : 1.375rem,
        medium      : 1.375rem,
        small       : 1rem
    );

$component-header-map : ( // gap between inline-block items or similar usage (utility nav items)
        null     : 2rem,
        medium      : .75rem,
        small       : .75rem
    );
$header-map-large : ( // gap between large headings
        null     : 2rem,
        medium      : 1.5rem,
        small       : 1.5rem
    );
$small-space-map : ( // gap between inline-block items or similar usage (utility nav items)
        null     : 1rem,
        large    : 1rem,
        medium      : 1rem,
        small       : .5rem
    );

$null-map : ( // gap between inline-block items or similar usage (utility nav items)
        null     : 0,
        large    : 0,
        medium      :0,
        small       : 0
    );

$block-margin-map: $grid-spacing-map; // for use between blocks. Extend %standard-margin-v
