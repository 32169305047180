﻿/// <reference path="../../entries/main">
/// <reference path="../../entries/main">
$button-form-padding: .625rem;
$max-input-width: 39.5rem;
/// minimum size for form element font-size to prevent iOS resizing is 16px
%form-element {
    font-size: $form-field-font-size;
    line-height: $form-field-line-height;
    padding: $button-form-padding;
    width: 100%;
    max-width: $max-input-width;
    border: none;
    border-radius: 0;
    font-family: $body-font;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,0);
    color: var(--input-color);
    background: var(--input-background);
    outline-offset: 0;
    outline: none;
    // if using a fixed nav, the scroll-margin-top value should match the nav height
    // this will make sure that jumping to a field via anchor link avoids a fixed nav at the top
    scroll-margin-top: $gap;

    &:focus {
        border-color: var(--input-background);
        outline: none;
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: var(--input-background-disabled);
        color: var(--input-disabled);

        &:hover {
            border-color: var(--input-background-disabled);
        }
    }

    &[aria-invalid="true"] {
        border-color: var(--input-color-disabled);
    }
}

.form-element-description {
    padding-left: 2rem;
    margin-top: .5rem;
    @extend %small;
}

::placeholder {
    color: var(--input-color-disabled);
    font-style: italic;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="reset"]):not([type=submit]) {
    @extend %form-element;
}

select, textarea {
    @extend %form-element;
}

submit, input[type="submit"] {
    @extend %btn-primary;
    font-family: $body-font;
}

.label-hint {
    @extend %small;
    font-style: italic;
}

.required {
    color: var(--input-color);
    position: relative;
    vertical-align: baseline;
    top: 0.025em;
    left: 0.125em;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

label {
    display: block;
}

input[type="checkbox"],
input[type="radio"] {
    & + label {
        display: inline-block;
        line-height: 1.375;
    }
}

.EPiServerForms {
    .Form__Element {
        &[type="submit"] {
            @extend %btn-primary;
            vertical-align: middle;
        }

        &[type="reset"] {
            @extend %btn-primary;
            vertical-align: middle;
            margin-right: $gap-sm;
        }

        .FormChoice .FormChoice__Input:focus + .FormChoice__Label {
            border: none;
        }

        .FormChoice fieldset .choice-fieldset .input-container > div {
            margin-block: $gap-sm;
        }

        .FormFileUpload {
            input {
                background-color: transparent !important;
            }
        }

        .FormChoice--Image {
            .FormChoice--Image__Item {
                max-width: 100%;

                label {
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    overflow-wrap: anywhere;
                }
            }

            .input-container {
                display: flex;
                flex-flow: row wrap;
                gap: $gap-sm;

                img {
                    max-width: 250px;

                    @include media(mobile) {
                        max-width: 100%;
                    }
                }
            }
        }

        input:not([type="checkbox"]):not([type="radio"]):not([type="reset"]) {
            @extend %form-element;
        }

        select, textarea {
            @extend %form-element;
        }


        &:not([type="submit"]):not([type="reset"]):not(.FormStep), > div[lang="en"] {
            display: grid;
            width: 100%;
        }

        label:not(.FormChoice__Label), legend {
            font-weight: $font-weight-bold;
        }

        option {
            background-color: var(--background-color);

            &:hover, :active {
                background-color: var(--input-background);
            }
        }

        .FormCaptcha {
            display: flex !important;
            flex-flow: column;
            gap: $gap-sm;

            input {
                order: 1;
            }

            .FormCaptcha__Refresh {
                order: 3;
                @extend %btn-primary;
                max-width: $max-input-width;
            }

            .FormCaptcha__Image {
                order: 2;
                max-width: $max-input-width;
            }
        }

        .Form__Element__Caption:has(+ .form-tooltip) {
            min-width: auto;
        }
    }

    .FormStep .FormStep__Description, .Form__Description {
        margin-top: $space;
    }

    .ValidationRequired .Form__Element__Caption:after {
        content: " *" !important;
        color: var(--alert-red);
    }

    .ValidationFail {
        color: var(--input-color);

        input {
            color: var(--input-color);
        }

        .Form__Element__Caption:after {
            color: var(--alert-red);
        }

        .Form__Element__ValidationError {
            color: var(--alert-red);
        }

        &:not(.FormChoice) {
            label {
                color: var(--input-color);
            }
        }

        &.FormChoice {
            legend {
                color: var(--input-color);
            }

            label {
                color: var(--input-color);
            }
        }
    }

    .Form__Success .Form__Success__Message, div.Form__Status .Form__Success__Message {
        background-color: var(--background-color);
        color: var(--foreground);
    }

    nav.Form__NavigationBar {
        max-width: $max-input-width;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: $gap-sm;

        @include responsive-context($gutter-map-v) {
            margin-top: $responsive-variable;
        }

        @include media(small) {
            justify-content: space-between;
        }

        .Form__NavigationBar__ProgressBar {
            border: 1px solid var(--input-background);
            background-color: var(--input-background);
            flex-grow: 1;

            @include media(small) {
                width: 100%;
                order: 1;
            }

            .Form__NavigationBar__ProgressBar--Progress {
                background-color: var(--button-primary-background);
            }
        }

        .Form__NavigationBar__Action {
            @extend %btn-primary;
            background-image: none;
            border-radius: 0;
            height: auto;
            width: auto;
            max-width: 100%;

            @include media(small) {
                order: 2;
            }
        }
    }

    div.Form__Status .Form__Warning__Message {
        background-color: var(--secondary-background);
        color: var(--alert-red);
    }

    .Form__Title {
        margin-bottom: $gap-md;
    }
}

.form-tooltip {
    display: inline-block;
    position: relative;
    &-content {
        display: none;
        position: absolute;
        background: #fff;
        z-index: 999;
        padding: $space $gap-sm;
        bottom: 0rem;
        left: $gap;
        width: max-content;
        max-width: 50vw;
        box-shadow: -1px 1px 4px 0px var(--jewel-tertiary);
    }

    &:has(.form-tooltip-icon:hover), &:has(.form-tooltip-icon:active) {
        .form-tooltip-content {
            display: block;
        }
    }

    &-icon {
        color: var(--dark-primary);
    }
}

.choice-fieldset {
    display: flex;
    flex-flow: row wrap;
    gap: .5rem;
}

.input-container {
    width: 100%;
}
%formwrapper {
    select, textarea, input:not([type=checkbox]):not([type=radio]):not([type=reset]) {
        max-width: 100%;
    }
}

///multicolumn form support
.EPiServerForms {
    section.Form__Element {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        gap: 1.38rem;

        > .block {
            width: 100%;
            &.displaymode-full {
                width: 100%;
                @extend %formwrapper;
            
            }
            &.displaymode-half {
                width: calc(50% - .69rem);
                @extend %formwrapper;
                @include media(small) {
                    width: 100%;
                }
            }
            &.displaymode-one-third {
                width: calc(33.33% - .92rem);
                @extend %formwrapper;
                @include media(small) {
                    width: 100%;
                }
            }
            &.displaymode-one-quarter {
                width: calc(25% - 1.1rem);
                @extend %formwrapper;
                @include media(small) {
                    width: 100%;
                }
            }
            &.displaymode-three-quarters {
                width: calc(75% - .69rem);
                @extend %formwrapper;
                @include media(small) {
                    width: 100%;
                }
            }
            &.displaymode-two-thirds {
                width: calc(66.66% - .46rem);
                @extend %formwrapper;
                @include media(small) {
                    width: 100%;
                }
            }
            
        }

        
    }
}
