﻿/// <reference path="../entries/main">
@use "sass:math";
$article-img-size: 26rem;
.article-detail {
    &-hero {
        &-top {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            gap: $gap;

            &.max-image-height {
                .article-detail-hero-img img {
                    max-height: 250px;
                }
            }

            &:has(.article-detail-hero-img) {
                width: 100%;


                @include media(medium) {
                    flex-direction: column-reverse;
                }
            }

            &:not(:has(.article-detail-hero-img)) {
                .article-detail-hero-content {
                    max-width: 100%;
                }
            }
        }

        &-subtitle .chip-subtitle {
            display: inline-block;
        }

        &-content {
            display: flex;
            flex-flow: column;
            gap: $gap-md;
            flex-grow: 1;
            max-width: calc(100% - #{($article-img-size + 2)});

            @include media(medium) {
                max-width: 100%;
            }
        }

        &-img {
            max-width: $article-img-size;

            @include media(medium) {
                max-width: $article-img-size;
                margin-inline: auto;
                width: 100%;
            }

            img {
                @include media(medium) {
                    max-width: 100%;
                }
            }
        }

        &-bottom {
            display: flex;
            flex-flow: row wrap;
            gap: $gap;
            justify-content: space-between;
            margin-top: $gap-md-lg;
            align-items: center;
            flex-grow: 1;

            &:not(:has(.article-detail-hero-sponsor)) {
                justify-content: flex-end;
            }
        }

        &-categories {
            @include media(medium) {
                width: 100%;
            }

            &-list {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                gap: $space;

                @include media(medium) {
                    justify-content: flex-start;
                }
            }
        }

        &-sponsor-name {
            margin-bottom: $sliver;
        }

        > .content-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            column-gap: $gap-lg;
            align-items: flex-end;
        }
    }

    &-sponsor {
        gap: $gap-sm;

        @include media(medium) {
            width: 100%;
        }
    }

    &-highlight {
        background-color: var(--secondary-background);

        .content-container {
            padding-block: $gap;
        }

        &-content {
            text-align: center;
        }
    }

    &-content {
        display: flex;
        flex-flow: column;
        gap: $gap-md;

        &-wrap {
            display: grid;
            grid-template-columns: minmax(175px,1fr) minmax(300px,3fr) minmax(300px,1fr);
            gap: $gap;

            @include media(medium) {
                grid-template-columns: minmax(100%, 1fr);
            }

            @include media-min(large) {
                grid-template-columns: minmax(250px,1fr) minmax(300px,3fr) minmax(300px,1fr);
            }

            @include media-min(x-large) {
                grid-template-columns: minmax(300px,1fr) minmax(300px,4fr) minmax(300px,1fr);
            }
        }
    }

    &-author {
        max-width: 100%;



        @include media(medium) {
            max-width: calc(50% - .5rem);
            text-align: left;
            gap:0;
        }

        @include media(small) {
            width: 100%;
            max-width: 100%;
            gap:$gap-sm;
        }

        &-header {
            width: 100%;
        }

        &-content {
            width: 100%;
            display: flex;
            flex-flow: column;
        }
    }

    &-form .EPiServerForms .Form__Element:last-child {
        margin-bottom: 0;
    }

    &-related {
        &-title {
            margin-bottom: $gap-md;
        }

        &-links {
            margin-top: $gap-md-lg;

            a {
                @extend %h5;
            }

            li + li {
                margin-top: $gap-md;
            }

            &-desktop {

                @include media(medium) {
                    display: none;
                }
            }
        }
    }

    &-publish {
        &-header {
            margin-bottom: $gap-sm;
        }

        &-date {
            max-width: 100%;



            @include media(medium) {
                max-width: calc(50% - .5rem);
                margin-bottom: $gap-sm;
            }

            @include media(mobile) {
                width: 100%;
                max-width: 100%;
                margin-bottom: $space;
            }
        }
    }

    &-sidebar {
        &-left {
            display: flex;
            flex-flow: column;
            gap: $gap;

            @include media(medium) {
                display: flex;
                flex-flow: row wrap;
                gap: $gap-md-lg;
                justify-content: flex-start;
            }
        }

        &-right {
            @include media(medium) {
                display: flex;
                flex-flow: row wrap;
                gap: $gap-md;
            }

            @include media(small) {
                display: block;
            }
        }
    }

    &-social {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        width: 100%;
        gap: $space;
        justify-content: space-between;

        @include media(medium) {
            justify-content: flex-start;
        }

        a {
            @extend %un-button;
        }
    }

    &-advertisement {
        .advertisementblock + .advertisementblock {
            margin-block: $gap-sm;
        }

        .advertisement-block {
            @extend .theme-light;
            @extend .theme-light-primary;
            padding: 0;
        }
    }

    &-advertisement {
        display: flex;
        flex-flow: column;

        &-container {
            display: flex;
            flex-flow: column;
            row-gap: $gap;
            justify-content: flex-start;
            flex-grow: 1;
        }
    }
}