﻿/// <reference path="../../entries/main">
.breadcrumbs {
    .content-container {
        @include responsive-context($small-space-map) {
            padding-block: $responsive-variable;
        }
    }

    &-divider {
        margin-inline: $sliver;
        line-height: $body-line-height;
    }
}
.breadcrumb-page-name {
    line-height: $body-line-height;
}