﻿/// <reference path="../../entries/main">

%rich-text,
.rich-text {
    max-width: $max-line-length;
    word-wrap: break-word;

    * + * {
        margin-top: $gap-xs;
    }

    * {
        + h1, + h2, + h3 {
            @include responsive-property('margin-top', $header-map-large);
        }

        + h4, + h5 {
            @include responsive-property('margin-top', $small-gutter-map-h);
        }
    }

    h1, h2, h3, h4, h5 {
        clear: both;
    }

    .preheader + * {
        @extend %small-top-margin;
    }

    ul,
    ol {
        padding-left: calc(0.66em + 6px);

        &.no-bullets {
            list-style-type: none;
            padding-left: 0;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    img[style*="float"] {
        margin-right: $gap-md;
        padding: $space;
        max-width: 50%;
    }

    img[style*="float: left;"], img[style*="float:left;"] {
        margin-right: $gap-md;
        padding: $space;
    }

    img[style*="float: right;"], img[style*="float:right;"] {
        margin-left: $gap-md;
        margin-right: 0;
        padding: $space;
    }

    a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)) {
        color: var(--link-color);

        &:hover, &:visited, &:focus {
            color: var(--link-color-active);
        }
    }

    table {
        a:not(.btn-primary):not(.btn-secondary):not(.btn-tertiary):not(:has(.btn-secondary)):not(:has(.btn-primary)) {
            color: var(--button-primary-foreground);

            &:hover, &:visited, &:focus {
                color: var(--button-primary-foreground);
            }
        }
    }
}
