﻿/// <reference path="../../entries/main">

.btn,
.btn-primary {
    @extend %btn-primary;
}
.btn-outline {
    @extend %btn-outline;
}
.btn-small {
    @extend %btn-small;
}
.icon-link {
    @extend %icon-link;
}
.btn-icon, .btn-secondary {
    @extend %btn-icon;
}
.btn-light {
    @extend %btn-primary;
    @extend %btn-light;
}

.btn-link {
    @extend %btn-link;
}

.btn-remove {
    @extend %btn-remove;
}

.un-button {
    @extend %un-button;
}

.btn-full-width {
    width: 100%;
    max-width: 100%;
}