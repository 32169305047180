﻿/// <reference path="../entries/main">
.content-highlight {
    &-block {
        &-item {
            &-image {
                width: 100%;
                aspect-ratio: 1.72;

                img {
                    aspect-ratio: 1.72;
                    object-fit: cover;
                }
            }

            &-link a {
                @extend %icon-link;
            }

            &-description {
                max-height: 4.125rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @supports (display: -webkit-box) {
                &-description {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    &-item {
        height: auto;
        width: calc(25% - 1.5rem);
        display: flex;
        flex-flow: column;
        gap: 1rem;

        &:first-child:nth-last-child(1) {
            width: 100%;
        }

        &:last-child {
            margin-right: 0 !important;
        }

        @include media-min(small) {
            width: calc(50% - 1rem);
            /* two items */
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ .content-highlight-item {
                width: calc(50% - 1rem);
            }
            /* three items */
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ .content-highlight-item {
                width: calc(50% - 1rem);
            }
            /* four items */
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ .content-highlight-item {
                width: calc(50% - 1rem);
            }

            &:first-child:nth-last-child(1) {
                width: 100%;
            }
        }

        @include media-min(medium) {
            width: calc(25% - 1.5rem);
            /* two items */
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ .content-highlight-item {
                width: calc(50% - 1rem);
            }
            /* three items */
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ .content-highlight-item {
                width: calc(33.3333% - 1.375rem);
            }
            /* four items */
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ .content-highlight-item {
                width: calc(33.3333% - 1.375rem);
            }
        }

        @include media-min(large) {
            width: calc(25% - 1.5rem);
            /* two items */
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ .content-highlight-item {
                width: calc(50% - 1rem);
            }
            /* three items */
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ .content-highlight-item {
                width: calc(33.3333% - 1.375rem);
            }
            /* four items */
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ .content-highlight-item {
                width: calc(25% - 1.5rem);
            }
        }

        @include media(small) {
            width: 100%;
        }

        &-wrapper {
            background-color: var(--card-background);
            border: solid 1px var(--card-border);
            display: flex;
            flex-flow: column;
            gap: $gap-xs;
            height: 100%;
            justify-content: space-between;

            @include responsive-context($small-gutter-map-h) {
                padding: $responsive-variable;
            }
        }

        &-content {
            flex-grow: 1;
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            &-wrapper {
                display: flex;
                flex-flow: column;
                gap: $space;
            }
        }
    }

    &-container-block {
        overflow: hidden;

        &-items {
            overflow: visible;
        }

        .swiper {
            &-wrapper {
                @include responsive-context($small-gutter-map-h) {
                    margin-block: $responsive-variable;
                }
            }

            &-button {
                &-lock {
                    display: none;
                }
            }
        }
    }
}