﻿/// <reference path="../entries/main">
@use "sass:math";
.article-landing {
    &-standard {
        border-color: var(--card-border);
    }

    &-highlighted {
        background-color: var(--jewel-primary);
        border-color: var(--jewel-primary);
    }

    &-result {
        padding: $gap-xs $gap-md;
        border-width: 1px;
        border-style: solid;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        @include responsive-context($gutter-map-small) {
            gap: $responsive-variable;
        }

        + .article-landing-result {
            @include responsive-context($gutter-map-small) {
                margin-top: $responsive-variable;
            }
        }

        &-sponsor {
            display: flex;
            flex-flow: row wrap;
            gap: $gap-sm;

            &-img {
                width: 42px;
                height: 42px;
                border-radius: 30px;
            }
        }

        &-content {
            display: flex;
            flex-flow: column;
            gap: $gap-sm;

            @include responsive-context($gutter-map-small) {
                max-width: calc(65% - #{math.div($responsive-variable, 2)});
            }

            @include media(small) {
                order: 2;
                max-width: 100%;
            }
        }

        &-img {
            width: 100%;
            object-fit: contain;

            @include responsive-context($gutter-map-small) {
                max-width: calc(35% - #{math.div($responsive-variable, 2)});
            }

            @include media(small) {
                order: 1;
                width: 100%;
                max-width: 100%;
            }
        }

        &-description {
            .btn-link {
                text-align: left;

                @include responsive-context($null-map) {
                    padding: $responsive-variable;
                }
            }

            p {
                margin-top: $space;
            }
        }

        &.is-sponsored {
            background-color: var(--secondary-background);
            border-color: var(--card-border);
        }

        &-category {
            padding: 0;
            color: var(--classic-primary);

            &-list {
                display: flex;
                flex-flow: row wrap;
                gap: $space;
            }
        }

        &-list {
            > .subtitle {
                margin-bottom: $gap-xs;
            }
        }
    }





    > .content-container {
        display: grid;
        grid-template-columns: minmax(300px,1fr) 3fr;
        gap: $gap-lg;

        @include media(large) {
            gap: $gap;
        }

        @include media(medium) {
            grid-template-columns: 1fr;
            gap: $gap-md;
        }
    }

    &-sidebar {
        overflow: hidden;

        .btn-secondary {
            margin-top: $gap-md;

            @include media-min(medium) {
                margin-bottom: $gap-md;
            }
        }
    }
}
.recent-blog-post {
        margin-top:$gap-xs;
        &-list {margin-bottom:2rem;}

}
.pinned-articles {
    @include responsive-context($small-gutter-map-v) {
        margin-bottom: $responsive-variable;
    }
}

//facets
.facet {
    &-header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: $gap-sm;
    }

    &-list {
        display: flex;
        flex-flow: column;
        gap: $gap-md;

        @include media-min(medium) {
            gap: $gap-md-lg;
        }
    }

    &-item {
        h3 {
            margin-bottom: $space;
        }

        &-list {
            padding-block: $space;
            margin-top: $space;
            overflow: hidden;
            &.closed {
                display:none;
                .facet-checkbox {
                    display:none;
                    height:0;
                    + .facet-checkbox {
                        margin-top: 0;

                    }
                   }

            }
        }

        &-mobile-trigger, &-mobile-trigger[aria-expanded=true] {
            background-color: transparent;
            color: inherit;
            padding: 0;
        }
    }

    &-checkbox + .facet-checkbox {
        margin-top: $space;
    }

    &-container {
        display: flex;
        flex-flow: column;
        gap: $gap-sm;

        @include media(medium) {
            border-top: solid 1px;
            border-color: var(--card-border);
            padding-top: $gap-xs;
        }
    }

    &-reset {
        padding-inline: 0;
        color: var(--classic-primary);
    }
}
.accordion-content-facet {
    background-color: transparent;
    padding-inline: 0;
}

.mobile-filter-accordion {
    .accordion-panel[aria-hidden=false] {
        padding-bottom: $gap-md;

        &.accordion-content-facet {
            padding-bottom: 0;
            > p {
                margin-top: $gap-sm;
            }
        }
    }

    .accordion-trigger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: solid 1px;
        border-color:transparent;

        &:focus {
            background-color: transparent;
        }

        &:focus-visible {
            outline: none;
            border-color: var(--secondary-background);
        }
    }

    input[type=checkbox]:focus + label {
        box-shadow: none;
        border: solid 1px var(--classic-tertiary);
    }

    input[type=checkbox] + label {
        box-shadow: none;
        border: solid 1px transparent;
        padding-right:$sliver;
    }
}