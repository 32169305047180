﻿/// <reference path="../entries/main">
body:has(.float-block-open) div#lhnHocButton.lhnslide {
    bottom: 15rem;
    right: 0;
}
div#lhnHocButton div.lhnHocChatTxtCont span {
    font-family: "AcherusGrotesque", Arial, sans-serif;
    font-size: 1rem;
}

div#lhnHocButton div.lhnHocChatBtnCont {
    background-color: var(--jewel-primary);
    height: auto;
}

div#lhnHocButton div.lhnHocChatBtn {
    background-color: var(--jewel-primary);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

div#lhnHocButton div.lhnHocChatBubbleCont {
    height: 30px;
    float:none!important;
}

div#lhnHocButton div.lhnHocChatTxtCont {
    height: 20px;
    float: none !important;
    height: auto;
}

div#lhnHocButton div.lhnHocFrontBubble:before {
    border: 0;
    content: "\ea21";
    font-family: "Phosphor" !important;
    font-size: 2rem;
    position: relative;
    left: auto;
    top: auto;
    color: white;
    line-height: 1.5rem;
}

div#lhnHocButton div.lhnHocChatBubble:after {
    display: none;
}

div#lhnHocButton div.lhnHocCtCont {
    display: none !important;
}

div#lhnHocButton div.lhnHocFrontBubble {
    background: none;
    width: auto;
    height: auto;
    margin-right: 0;
}

div#lhnHocButton.lhnslide div.lhnHocBackBubble {
    display: none !important;
}

div#lhnHocButton.lhnslide div.lhnHocChatBubble {
    margin-right: 0;
}

div#lhnHocButton.lhnslide .lhnHocChatBtnCont {
    border-radius: 0;

    @include media(small) {
        padding: 10px 12px;
    }
}
div#lhnHelpOutCenter.lhnWindow *, div#lhnHelpOutCenter.lhnWindow :after, div#lhnHelpOutCenter.lhnWindow :before {
    font-family: $body-font;
}
#lhnHelpOutCenter div.lhnWindow-header {
    background-color: var(--jewel-primary);
    border-radius:0;
    * {
        color: white;
        font-family: $heading-font;
    }
}
div#lhnHocButton div.lhnHocButtonClear {
    display:none;
}

div#lhnHocButton div.lhnHocChatTxtCont {
    @extend %visually-hidden;
    /* show the text on desktop
    @include media-min(small) {
        margin: auto;
        padding: 0;
        width: auto;
        height: auto;
        overflow: hidden;
        clip: initial;
        position: relative;
    }*/
}

#lhnHelpOutCenter .lhnSurveyForm div.lhnRadioGroup input:checked + span, #lhnHelpOutCenter button.form_submit, #lhnHelpOutCenter button.lhnFormButton {
    background-color: var(--dark-primary);
    text-shadow: none;
    border-radius: 0;
}

#lhnHelpOutCenter div.lhnWindow-header div.lhnWindow-header-controls img {
    filter: none;
}

.atlwdg-trigger.atlwdg-RIGHT {
    top:47%;
}

div#lhnHocButton.lhnslide {
    bottom: 10rem;
    right: 0;
}